* {
  color: #fff;
  box-sizing: border-box;
  background-color: #202020;
}

:root {
  font-size: 10px;
}

body {
  margin-inline: 0;
}

.video-content-manager {
  height: 99vh;
  grid-template-rows: 56px minmax(80vh, auto) 56px;
  grid-template-areas: "toolbar"
                       "videos-result"
                       "navbar";
  gap: 20px 10px;
  display: grid;
}

.video-content-manager_toolbar {
  grid-area: toolbar;
  display: flex;
}

.video-content-manager_toolbar_navbar-action {
  flex: auto;
  justify-content: start;
  display: flex;
}

.video-content-manager_toolbar_search-box {
  flex: none;
  justify-content: center;
  display: flex;
}

.video-content-manager_toolbar_search-box_writing {
  flex: auto;
  display: flex;
}

.video-content-manager_toolbar_search-box_writing_box, .video-content-manager_toolbar_search-box_audio {
  display: none;
}

.video-content-manager_toolbar_search-box_writing_box_action-button {
  flex: none;
}

.video-content-manager_toolbar_admin {
  flex: auto;
  justify-content: end;
  display: flex;
}

.video-content-manager_toolbar_admin_add-video {
  display: none;
}

.video-content-manager_toolbar_admin_create-video-button, .video-content-manager_toolbar_admin_notification-button, .video-content-manager_toolbar_admin_account-button {
  flex: auto;
}

.video-content-manager_toolbar_admin_account-photo {
  width: 14px;
  height: 42px;
  object-fit: contain;
  border-radius: 50%;
  flex: auto;
  display: block;
}

.video-content-manager_navbar {
  visibility: visible;
  width: 100vw;
  grid-area: navbar;
}

.video-content-manager_navbar_links {
  display: flex;
}

.video-content-manager_navbar_links_item {
  flex-direction: column;
  flex: auto;
  justify-content: center;
  display: flex;
}

.video-content-manager_navbar_links_item_icon {
  text-align: center;
}

.video-content-manager_navbar_links_item_text {
  text-align: center;
  font-size: 1rem;
}

.video-content-manager_navbar_footer {
  display: none;
}

.video-content-manager_videos-results {
  background-color: #181818;
  grid-area: videos-result;
  grid-auto-rows: 400px;
  gap: 10px;
  display: grid;
  overflow: auto;
}

.video-content-manager_videos-results_video {
  width: 100%;
  overflow: auto;
}

.video-content-manager_videos-results_video_details {
  width: 100%;
  padding: 2px 16px;
}

.video-content-manager_videos-results_video_frame {
  width: 100%;
  height: 66%;
}

.text-button {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  border: none;
  padding: 6px 10px;
}

.text-button:active {
  background-color: #2e2e2e;
}

.link-button {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
}

.link-button:hover {
  background-color: #2e2e2e;
}

.link-button:active {
  background-color: #383838;
}

.img-button {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  border: none;
}

.img-button:active {
  border: thin solid #00f;
}

.card {
  transition: all .3s;
  box-shadow: 0 4px 8px #0003;
}

.card:hover {
  box-shadow: 0 8px 16px #0003;
}

@media (min-width: 500px) {
  .video-content-manager {
    grid-template: "toolbar toolbar" 56px
                   "navbar videos-result" 1fr
                   / 1fr 7fr;
  }

  .video-content-manager_toolbar_search-box {
    flex: auto;
  }

  .video-content-manager_toolbar_search-box_writing_box {
    color: #838383;
    background-color: #121212;
    flex: auto;
    display: block;
  }

  .video-content-manager_toolbar_search-box_audio {
    border-radius: 50%;
    flex: none;
    margin-inline-start: 4px;
    display: block;
  }

  .video-content-manager_toolbar_admin_add-video {
    color: #838383;
    background-color: #121212;
    flex: auto;
    display: block;
  }

  .video-content-manager_toolbar_admin_create-video-button, .video-content-manager_toolbar_admin_notification-button, .video-content-manager_toolbar_admin_account-button {
    flex: none;
  }

  .video-content-manager_toolbar_admin_account-photo {
    width: 32px;
    height: 42px;
    flex: none;
  }

  .video-content-manager_navbar {
    width: auto;
    flex-direction: column;
    display: flex;
  }

  .video-content-manager_navbar_links {
    flex-direction: column;
    display: flex;
  }

  .video-content-manager_navbar_links_item {
    height: 36px;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
  }

  .video-content-manager_navbar_links_item_icon {
    text-align: center;
    flex: none;
    margin-right: 24px;
  }

  .video-content-manager_navbar_links_item_text {
    text-align: start;
    flex: 1 0 auto;
  }

  .video-content-manager_videos-results {
    background-color: #181818;
    grid-area: videos-result;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-auto-rows: 400px;
    gap: 10px;
    padding: 0 50px;
    display: grid;
    overflow: auto;
  }
}

/*# sourceMappingURL=index.1da75c43.css.map */
